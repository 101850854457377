@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

$bg-color: #f1f1f1;
$primary-color: #000;
$highlight-color:#3b5998;
$light-color: #fff;
$border-color: #ddd;
$ff-primary: 'Poppins', sans-serif;

@mixin break {
  thead {
    display: none;
  }

  tr {
    display: block;
    margin-bottom: 5px;
  }

  td {
    display: block;
    position: relative;
    padding-left: 130px;
    text-align: left;
    border-bottom: 0;

    &:last-child {
      border-bottom: 1px solid $border-color;
    }

    &::before {
      content: attr(data-heading);
      position: absolute;
      top: 0;
      left: 0;
      width: 120px;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: $primary-color;
      color: $light-color;
      font-size: 0.75rem;
      padding: 0 5px;
      justify-content: center;
    }
  }
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
} 

body {
  font-family: $ff-primary;
}

.header {
  padding: 5px 5px;
  background-color: $bg-color;
  text-align: left;
  margin-bottom: 20px;
  //font-weight: bold;
}

.table-container {
  max-width: 800px;
  width: 90%;
  margin: 0 auto 20px;

  &__title {
    background-color: $highlight-color;
    color: $light-color;
    text-align: left;
    padding: 10px;

  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    thead {

      tr {
        background-color: transparent;
      }
    }

    td, th {
      border: 1px solid $border-color;
      padding: 10px;
      text-align: center;
    }

    tr {

      &:nth-child(even) {
        background-color: $bg-color;
      }
    }

    &--break-lg {

      @media (max-width: 1991px) {
        @include break;
      }
    }

    &--break-md {

      @media (max-width: 991px) {
        @include break;
      }
    }

    &--break-sm {

      @media (max-width: 575px) {
        @include break;
      }
    }
  }
}